import './App.css';
import React from 'react';

class RedirectPage extends React.Component {
  componentDidMount(){
    window.location.replace('https://forms.office.com/r/uJxiCTjiiP')
  }
  render(){
    return <></>;
  }
}

function App() {
  return <RedirectPage />;
}

export default App;
